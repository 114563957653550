const conf = {
  urls: {
    benefits: '/api/benefits.json',
    categories: '/api/categories.json',
    certificates: '/api/certificates.json',
    contact: '/api/contact.json',
    interest: '/api/interest.json',
    skills: '/api/skills.json',
    works: '/api/works.json',
    work: '/api/work',
  },
  routes: {
    home: "/",
    about: "/about",
    projects: "/projects",
    contact: "/contact",
    works: "/works",
  },
  particles: {
    about: {
      particles: {
        number: {
          value: 100,
          density: {
            enable: true,
            value_area: 800
          }
        },
        size: {
          value: 3,
          random: true
        },
        line_linked: {
          enable: false
        },
        move: {
          enable: true,
          speed: 6,
          direction: "bottom",
          random: true,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200
          }
        }
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "repulse"
          }
        }
      }
    },
    landing: {
      particles: {
        fpsLimit: 120,
        interactivity: {
          events: {
            onClick: {
              enable: true,
              mode: "push",
            },
            onHover: {
              enable: true,
              mode: "repulse",
            },
            resize: true,
          },
          modes: {
            push: {
              quantity: 2,
            },
            repulse: {
              distance: 400,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            value: "#ffffff",
          },
          links: {
            color: "#ffffff",
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          collisions: {
            enable: true,
          },
          move: {
            directions: "none",
            enable: true,
            outModes: {
              default: "bounce",
            },
            random: false,
            speed: 1,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
            },
            value: 50,
          },
          opacity: {
            value: 0.5,
          },
          shape: {
            type: "circle",
          },
          size: {
            value: { min: 1, max: 2 },
          },
        },
        detectRetina: true,
      },
      interactivity: {
        events: {
          onhover: {
            enable: true,
            mode: "repulse"
          }
        }
      }
    }
  },
  typing: {
    list: [
      2000,
      "Software Engineer",
      1000,
      "Full Stack Engineer",
      1000,
      "Frontend Engineer",
      1000,
      "Backend Engineer",

    ],
  },
  modules: {
    header: {
      fullName: "Oleg Dudkin",
      icon: `M 65.787109 87.697266 C 55.176137 87.697266 45.828692 92.400821 37.744141 101.80859 C 29.65959 111.10023 
      25.617188 122.13416 25.617188 134.91016 L 25.617188 194.49219 C 25.617188 199.37029 24.758983 202.97201 23.041016 
      205.29492 C 22.030447 206.80481 19.150064 207.9086 14.400391 208.60547 C 9.7517736 209.1862 6.1650939 211.04376 
      3.6386719 214.17969 C 1.2133066 217.31561 -2.9605947e-16 221.49611 0 226.72266 C 0 231.83305 1.2133066 235.95782 
      3.6386719 239.09375 C 6.1650939 242.22968 9.7517736 244.14492 14.400391 244.8418 C 19.049007 245.42252 22.081297 
      246.64167 23.496094 248.5 C 24.91089 250.35833 25.617188 253.78504 25.617188 258.7793 L 25.617188 321.67188 C 
      25.617187 329.6859 27.083022 336.71239 30.013672 342.75195 C 34.662289 352.04358 40.119239 358.72202 46.384766 
      362.78711 C 52.650293 366.96834 59.168642 369.06055 65.939453 369.06055 C 70.689127 369.06055 74.579622 367.31835 
      77.611328 363.83398 C 80.744092 360.34962 82.310547 356.0518 82.310547 350.94141 C 82.310547 346.0633 80.947286 
      341.94048 78.21875 338.57227 C 75.490214 335.20405 72.002202 333.28685 67.757812 332.82227 C 63.513424 332.47383 
      60.583707 331.25468 58.966797 329.16406 C 57.450944 327.18959 56.693359 323.82055 56.693359 319.05859 L 56.693359 
      262.26367 C 56.693359 252.27517 56.036396 245.07368 54.722656 240.66016 C 53.408917 236.13049 50.93297 231.48462 
      47.294922 226.72266 C 50.831913 221.9607 53.256574 217.43215 54.570312 213.13477 C 55.985109 208.72124 56.693359 
      202.50622 56.693359 194.49219 L 56.693359 137.69727 C 56.693359 132.9353 57.602851 129.33554 59.421875 126.89648 
      C 60.533501 125.27045 63.261976 124.22435 67.607422 123.75977 C 71.952868 123.29518 75.490214 121.37994 78.21875 
      118.01172 C 80.947286 114.6435 82.310547 110.57837 82.310547 105.81641 C 82.310547 100.70601 80.744092 96.40819 
      77.611328 92.923828 C 74.579622 89.439466 70.63784 87.697266 65.787109 87.697266 z M 387.60352 87.697266 C 
      382.85384 87.697266 378.91206 89.439466 375.7793 92.923828 C 372.74759 96.40819 371.23242 100.70601 371.23242 
      105.81641 C 371.23242 110.57837 372.59764 114.6435 375.32617 118.01172 C 378.05471 121.37994 381.54077 123.29518 
      385.78516 123.75977 C 390.02955 124.22435 392.90993 125.50313 394.42578 127.59375 C 396.04269 129.56822 396.84961 
      132.9353 396.84961 137.69727 L 396.84961 194.49219 C 396.84961 202.27393 397.50657 208.43127 398.82031 212.96094 
      C 400.23511 217.37446 402.71106 221.9607 406.24805 226.72266 C 402.50894 231.60076 399.98366 236.24663 398.66992 
      240.66016 C 397.45724 245.07368 396.84961 252.27517 396.84961 262.26367 L 396.84961 319.05859 C 396.84961 
      323.82055 395.94012 327.42227 394.12109 329.86133 C 393.00947 331.48736 390.28099 332.53151 385.93555 332.99609 C 
      381.5901 333.46068 378.05471 335.37788 375.32617 338.74609 C 372.59764 342.11431 371.23242 346.17945 371.23242 
      350.94141 C 371.23242 356.0518 372.74759 360.34962 375.7793 363.83398 C 378.91206 367.31835 382.90513 369.06055 
      387.75586 369.06055 C 398.36683 369.06055 407.71428 364.35504 415.79883 354.94727 C 423.88338 345.65563 427.92578 
      334.564 427.92578 321.67188 L 427.92578 258.7793 C 427.92578 253.90119 428.78399 250.30142 430.50195 247.97852 C 
      431.51252 246.46863 434.34162 245.42252 438.99023 244.8418 C 443.73991 244.14492 447.32854 242.22968 449.75391 
      239.09375 C 452.28033 235.95782 453.54297 231.83305 453.54297 226.72266 C 453.54297 221.49611 452.28033 217.31561 
      449.75391 214.17969 C 447.32854 211.04376 443.79119 209.1862 439.14258 208.60547 C 434.49396 208.02474 431.46166 
      206.80364 430.04688 204.94531 C 428.63208 202.97084 427.92578 199.48644 427.92578 194.49219 L 427.92578 134.91016 
      C 427.92578 127.01227 426.45995 120.04347 423.5293 114.00391 C 418.88068 104.71227 413.42373 98.033839 407.1582 
      93.96875 C 400.89268 89.787516 394.37433 87.697266 387.60352 87.697266 z M 313.50391 136.0625 C 307.59698 136.0625 
      303.36685 137.39419 300.8125 140.05469 C 298.33798 142.62345 297.09961 146.01622 297.09961 150.23633 C 297.09961 
      154.36469 298.33798 157.75942 300.8125 160.41992 C 303.36685 162.98868 307.59698 164.27344 313.50391 164.27344 L 
      317.69531 164.27344 L 317.69531 199.36523 C 311.70857 194.8699 305.64053 191.52015 299.49414 189.31836 C 293.34775 
      187.02482 287.08313 185.87891 280.69727 185.87891 C 263.69491 185.87891 249.2865 192.62143 237.47266 206.10742 C 
      225.65881 219.59342 219.75195 235.87676 219.75195 254.95898 C 219.75195 273.21554 225.37968 288.4918 236.63477 
      300.78516 C 247.88985 312.98677 262.7366 319.08789 281.17578 319.08789 C 287.72129 319.08789 294.02718 318.12384 
      300.09375 316.19727 C 306.24014 314.27069 312.10768 311.42742 317.69531 307.66602 L 317.69531 314.54688 L 
      346.43164 314.54688 C 352.33856 314.54687 356.52938 313.26212 359.00391 310.69336 C 361.55825 308.03286 362.83398 
      304.63813 362.83398 300.50977 C 362.83398 296.28966 361.55825 292.89493 359.00391 290.32617 C 356.44956 287.66567 
      352.25874 286.33594 346.43164 286.33594 L 342.24023 286.33594 L 342.24023 136.0625 L 313.50391 136.0625 z M 
      139.05078 185.87891 C 127.95535 185.87891 117.29746 188.99791 107.08008 195.23633 C 96.942521 201.47475 89.160405 
      210.05152 83.732422 220.96875 C 78.304439 231.79424 75.589844 242.85057 75.589844 254.13477 C 75.589844 265.23548 
      78.265121 275.87724 83.613281 286.06055 C 88.961441 296.24385 96.743557 304.3161 106.96094 310.2793 C 117.17832 
      316.15075 127.87552 319.08789 139.05078 319.08789 C 150.30586 319.08789 161.0817 316.10577 171.37891 310.14258 C 
      181.75593 304.08764 189.53805 296.01344 194.72656 285.92188 C 199.9949 275.73857 202.62891 265.23565 202.62891 
      254.41016 C 202.62891 243.03422 199.9949 231.97789 194.72656 221.24414 C 189.45823 210.41865 181.63484 201.84188 
      171.25781 195.51172 C 160.96061 189.08982 150.22604 185.87891 139.05078 185.87891 z M 139.05078 214.08984 C 
      151.66286 214.08984 161.83917 219.22691 169.58203 229.50195 C 175.24948 237.11649 178.08398 245.46447 178.08398 
      254.54688 C 178.08398 263.4458 174.33151 271.7488 166.82812 279.45508 C 159.32474 287.06962 150.06639 290.87695 
      139.05078 290.87695 C 128.11499 290.87695 118.89401 287.06962 111.39062 279.45508 C 103.88724 271.7488 100.13672 
      263.49273 100.13672 254.68555 C 100.13672 243.9518 103.84792 234.50093 111.27148 226.33594 C 118.69505 218.17095 
      127.95535 214.08984 139.05078 214.08984 z M 280.93555 214.08984 C 291.23275 214.08984 299.93479 218.12597 
      307.03906 226.19922 C 314.14333 234.27247 317.69531 244.13399 317.69531 255.78516 C 317.69531 265.87672 314.46178 
      274.27162 307.99609 280.96875 C 301.53041 287.57414 292.50992 290.87695 280.93555 290.87695 C 269.28135 290.87695 
      260.26086 287.57414 253.875 280.96875 C 247.48914 274.27162 244.29688 265.73983 244.29688 255.37305 C 244.29688 
      243.99711 247.84885 234.27247 254.95312 226.19922 C 262.0574 218.12597 270.71817 214.08984 280.93555 214.08984 z `,
      view: "-10 -10 500 500",
      links: [
        {
          url: "/about",
          name: "about"
        },
        {
          url: "/skills",
          name: "skills"
        },
        {
          url: "/works",
          name: "works"
        }
      ]
    },
    landing: {
      parts: {
        skills: {
          header: 'Skills',
          url: '/api/categories.json'
        },
        certificates: {
          header: 'Certificates',
          url: '/api/certificates.json',
          button: 'Read more'
        },
        works: {
          header: 'Works',
          url: '/api/works.json'
        },
        contact: {
          header: 'Contact',
          url: '/api/contact.json'
        }
      }
    },
    about: {
      header: "My statistics ",
      url: "/api/benefits.json",
      icon: `M 125.33203 1.4394531 A 122.53849 122.53849 0 0 0 2.7929688 123.97852 A 122.53849 122.53849 0 0 0 125.33203
       246.51562 A 122.53849 122.53849 0 0 0 247.87109 123.97852 A 122.53849 122.53849 0 0 0 125.33203 1.4394531 z M 
       183.29102 68.091797 C 184.87917 68.077799 186.47384 68.180359 187.61133 68.404297 C 191.98039 69.264434 
       196.25908 71.52879 199.33203 74.607422 C 202.32298 77.6039 204.20789 80.948966 205.3418 85.279297 C 205.95912 
       87.636831 206.10181 91.600266 205.66406 94.21875 C 204.14101 103.32907 197.3627 110.48662 188.27539 112.58203 C 
       186.79444 112.92352 182.14081 113.17704 180.875 112.98438 C 176.45962 112.31233 173.15183 110.96635 169.89844 
       108.51953 C 165.3543 105.10197 162.23955 100.03373 161.27148 94.482422 C 160.91727 92.451244 160.91775 88.678005 
       161.27148 86.65625 C 162.86139 77.566921 169.75806 70.498391 179.00391 68.480469 C 180.121 68.23666 181.70286 
       68.105794 183.29102 68.091797 z M 67.134766 68.095703 C 68.708894 68.092637 70.299505 68.195537 71.349609 
       68.402344 C 75.911477 69.300762 79.958492 71.445519 83.169922 74.667969 C 89.585172 81.105238 91.515309 90.551193 
       88.136719 98.980469 C 84.346969 108.43555 74.404311 114.23688 64.269531 112.9082 C 56.454632 111.88365 49.411459 
       106.44565 46.431641 99.136719 C 45.160139 96.017966 44.647893 93.009243 44.798828 89.558594 C 45.057795 83.638148 
       47.355527 78.538339 51.630859 74.402344 C 54.815378 71.321611 58.491589 69.390666 63.021484 68.417969 C 64.003025 
       68.207204 65.560638 68.098769 67.134766 68.095703 z M 52.056641 153.57422 C 53.658074 153.61232 55.242239 
       153.94707 56.892578 154.58789 C 59.647643 155.65769 61.50744 157.27638 64.03125 160.79883 C 74.911747 175.98455 
       91.429178 186.76907 109.73633 190.64062 C 115.71852 191.90573 120.67672 192.3511 127.11523 192.20117 C 133.45724 
       192.0535 138.16205 191.41783 144.05859 189.91211 C 161.1308 185.55262 176.40152 175.11076 186.63867 160.79883 C 
       189.36216 156.99127 191.333 155.3898 194.66211 154.2793 C 202.13187 151.78759 210.15216 156.17576 212.27539 
       163.91797 C 212.71235 165.51135 212.72146 169.09679 212.29297 170.68359 C 211.60042 173.24812 209.96497 175.90998 
       206.10547 180.74805 C 185.04292 207.151 152.92603 221.60206 119.54297 219.69727 L 119.54102 219.69727 C 99.888206 
       218.57591 80.677301 211.70463 64.738281 200.0957 C 56.969584 194.4375 48.805426 186.41937 43.177734 178.91992 C 
       39.843609 174.47688 39.156579 173.30835 38.466797 170.91602 C 37.947562 169.11519 37.912194 165.6839 38.396484 
       163.91797 C 39.822417 158.71841 43.98219 154.81082 49.138672 153.82422 C 50.129018 153.63474 51.095781 153.55136 
       52.056641 153.57422 z `,
      parts: {
        interest: {
          header: "I'm interested in",
          url: "/api/interest.json"
        }
      }
    },
    works: {
      path: '/works',
      workUrl: '/api/works',
      url: '/api/works.json'
    },
    skills: {
      header: 'Skills',
      url: '/api/skills.json'
    }
  },
  icons: {
    github: {
      icon: `M186.1 328.7c0 20.9-10.9 55.1-36.7 55.1s-36.7-34.2-36.7-55.1 10.9-55.1 36.7-55.1 36.7 34.2 36.7 55.1zM480 
        278.2c0 31.9-3.2 65.7-17.5 95-37.9 76.6-142.1 74.8-216.7 74.8-75.8 0-186.2 2.7-225.6-74.8-14.6-29-20.2-63.1-20.2-95 
        0-41.9 13.9-81.5 41.5-113.6-5.2-15.8-7.7-32.4-7.7-48.8 0-21.5 4.9-32.3 14.6-51.8 45.3 0 74.3 9 108.8 36 29-6.9 
        58.8-10 88.7-10 27 0 54.2 2.9 80.4 9.2 34-26.7 63-35.2 107.8-35.2 9.8 19.5 14.6 30.3 14.6 51.8 0 16.4-2.6 32.7-7.7 
        48.2 27.5 32.4 39 72.3 39 114.2zm-64.3 50.5c0-43.9-26.7-82.6-73.5-82.6-18.9 0-37 3.4-56 6-14.9 2.3-29.8 3.2-45.1 
        3.2-15.2 0-30.1-.9-45.1-3.2-18.7-2.6-37-6-56-6-46.8 0-73.5 38.7-73.5 82.6 0 87.8 80.4 101.3 150.4 
        101.3h48.2c70.3 0 150.6-13.4 150.6-101.3zm-82.6-55.1c-25.8 0-36.7 34.2-36.7 55.1s10.9 55.1 36.7 55.1 36.7-34.2 
        36.7-55.1-10.9-55.1-36.7-55.1z`,
      view: '0 0 480 512'
    },
    web: {
      icon: `M 514.16992 10.486328 C 235.99272 10.486328 10.486328 235.99272 10.486328 514.16992 C 10.486328 792.34713 
        235.99272 1017.8516 514.16992 1017.8516 C 792.34713 1017.8516 1017.8516 792.34713 1017.8516 514.16992 C 1017.8516 
        235.99272 792.34713 10.486328 514.16992 10.486328 z M 535.25781 86.835938 C 594.60002 136.18171 640.58562 
        195.49607 673.21875 260.29688 L 535.25781 260.29688 L 535.25781 86.835938 z M 493.08203 87.365234 L 493.08203 
        260.29688 L 355.75586 260.29688 C 388.27051 195.72796 434.04787 136.60608 493.08203 87.365234 z M 429.57227 
        87.914062 C 377.94658 138.73747 337.69629 197.3393 308.81055 260.29688 L 161.53711 260.29688 C 175.11676 241.51928 
        190.26483 223.67487 206.9707 206.9707 C 268.71131 145.2284 345.76705 104.36774 429.57227 87.914062 z M 599.54492 
        88.060547 C 683.04885 104.6124 759.81784 145.41796 821.36719 206.9707 C 838.07475 223.67487 853.22308 241.51929 
        866.80273 260.29688 L 720.16211 260.29688 C 691.30513 197.40023 651.09952 138.8501 599.54492 88.060547 z M 
        134.59375 302.47266 L 291.39258 302.47266 C 279.19916 335.51712 269.99943 369.50618 263.74023 403.9707 L 
        93.771484 403.9707 C 103.00964 368.49571 116.70644 334.43385 134.59375 302.47266 z M 535.25781 302.47266 L 
        692.2793 302.47266 C 705.52382 335.38677 715.51297 369.40125 722.29688 403.9707 L 535.25781 403.9707 L 535.25781 
        302.47266 z M 737.58203 302.47266 L 893.74414 302.47266 C 911.63143 334.43216 925.33074 368.49571 934.57227 
        403.9707 L 765.23242 403.9707 C 758.97153 369.50618 749.77376 335.51881 737.58203 302.47266 z M 336.69141 
        302.47461 L 493.08008 302.47461 L 493.08008 403.97266 L 306.67578 403.97266 C 313.46307 369.40321 323.44859 
        335.38871 336.69141 302.47461 z M 173.72266 428.60156 L 207.45508 541.1875 L 233.58594 469.29883 L 266.89258 
        469.29883 L 293.02148 541.1875 L 326.75781 428.60156 L 357.01953 441.98047 L 309.58984 586.56641 L 279.79297 
        599.73633 L 250.23828 501.09766 L 220.68359 599.73633 L 190.88672 586.56641 L 143.45898 441.98047 L 173.72266 
        428.60156 z M 437.4082 428.60156 L 471.14258 541.18555 L 497.27148 469.29688 L 530.58008 469.29688 L 556.70898 
        541.18555 L 590.44141 428.60156 L 620.70703 441.97852 L 573.27734 586.56445 L 543.48438 599.73438 L 513.92383 
        501.0957 L 484.36719 599.73438 L 454.57227 586.56445 L 407.14258 441.97852 L 437.4082 428.60156 z M 701.58398 
        428.60156 L 735.32227 541.18555 L 761.45117 469.29688 L 794.75781 469.29688 L 820.88867 541.18555 L 854.62305 
        428.60156 L 884.88477 441.97852 L 837.45508 586.56445 L 807.6582 599.73438 L 778.10352 501.0957 L 748.54688 
        599.73438 L 718.75195 586.56445 L 671.32031 441.97852 L 701.58398 428.60156 z M 535.25781 624.36328 L 722.29688 
        624.36328 C 715.54174 658.77364 705.61797 692.63211 692.46484 725.40234 L 535.25781 725.40234 L 535.25781 
        624.36328 z M 93.771484 624.36914 L 263.74023 624.36914 C 269.97065 658.67625 279.1136 692.50738 291.2207 
        725.40625 L 134.33789 725.40625 C 116.57754 693.57879 102.96732 659.67489 93.771484 624.36914 z M 306.67578 
        624.36914 L 493.08203 624.36914 L 493.08203 725.40625 L 336.50586 725.40625 C 323.35275 692.63601 313.42921 
        658.77612 306.67578 624.36914 z M 765.23242 624.36914 L 934.56836 624.36914 C 925.37251 659.67318 911.76035 
        693.57879 894 725.40625 L 737.75195 725.40625 C 749.86075 692.50569 759.00369 658.67287 765.23242 624.36914 z M 
        161.20312 767.58203 L 308.60352 767.58203 C 337.49942 830.71056 377.82105 889.48025 429.57031 940.42383 C 
        345.76678 923.97185 268.70962 883.10951 206.9707 821.36719 C 190.12773 804.52593 174.86742 786.52888 161.20312 
        767.58203 z M 355.52344 767.58203 L 493.08203 767.58203 L 493.08203 940.97266 C 433.90738 891.61504 388.05164 
        832.32868 355.52344 767.58203 z M 535.25781 767.58203 L 673.45117 767.58203 C 640.80618 832.56226 594.74389 
        892.0394 535.25781 941.50195 L 535.25781 767.58203 z M 720.37109 767.58203 L 867.13477 767.58203 C 853.47048 
        786.52888 838.21016 804.52593 821.36719 821.36719 C 759.81784 882.91993 683.04937 923.7272 599.54883 940.27734 
        C 651.22698 889.36423 691.50058 830.64794 720.37109 767.58203 z`,
      view: '0 0 1024 1024'
    }
  }
};

export default (state = conf) => state;